@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "animate.css";
@import "react-datepicker/dist/react-datepicker.min.css";

.blur {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);

    &.blur-10 {
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
        filter: blur(10px);
    }
}

.ratings {
    li:hover {
        svg {
            fill: currentColor;
            stroke-width: 0;
        }
        & ~ li svg {
            fill: currentColor;
            stroke-width: 0;
        }
    }
}

.progress-ring__circle {
    transition: 0.35s stroke-dashoffset;
    // axis compensation
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

@layer components {
    .mobile-nav-link {
        @apply text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium;
    }

    .mobile-nav-link.active {
        @apply bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium;
    }

    .tab-button.active .tab-border {
        display: block;
    }

    .tab-button {
        @apply text-sm hover:text-primary-light inline-flex flex-col relative;
    }

    .tab-button.active {
        @apply text-primary font-bold;
    }

    .tab-button::after {
        content: "";
        display: none;
        @apply absolute border-b-4 border-primary -bottom-8 w-full;
    }

    .tab-button.active::after {
        display: block;
    }

    .text-input {
        @apply shadow-sm focus:ring-primary focus:border-primary mt-1 block w-full sm:text-sm border-gray-300 rounded-md;
    }

    .label {
        @apply text-sm text-primary font-semibold;
    }

    .checkbox {
        @apply h-4 w-4 text-primary-light focus:ring-primary border-gray-300 rounded;
    }
    .radio {
        @apply h-4 w-4 text-primary-light focus:ring-primary border-gray-300 rounded-full;
    }

    .checkbox-label {
        @apply block text-sm text-gray-900 select-none;
    }
}

.ReactSelect input {
    --tw-ring-color: transparent !important;
}

.ReactSelect__control {
    @apply rounded-md mt-1 #{!important};
}

.ReactSelect__control--is-focused {
    @apply border-2 border-primary shadow-none #{!important};
}

.with-connecting-lines {
    &::before {
        content: " ";
        height: 50%;
        position: absolute;
        z-index: 0;
        @apply border-l-2 left-2.5 top-0;
    }
    &::after {
        content: " ";
        height: 50%;
        position: absolute;
        z-index: 0;
        @apply border-l-2 left-2.5 bottom-0;
    }

    &:first-child::before {
        display: none;
    }
    &:last-child::after {
        display: none;
    }
}

.form-required::after {
    content: " *";
    @apply text-red-500;
}

.sticky-shadow {
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
}
